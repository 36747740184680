import { render, staticRenderFns } from "./default-template.vue?vue&type=template&id=e6fd5f98&"
import script from "./default-template.ts?vue&type=script&lang=ts&"
export * from "./default-template.ts?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Header: require('/opt/jenkins/workspace/ospbuild/osp-store-repository/node/packages/shop/components/header/header.vue').default,MessageboxWrapper: require('/opt/jenkins/workspace/ospbuild/osp-store-repository/node/packages/shop/components/molecules/messagebox-wrapper/messagebox-wrapper.vue').default,ScrollToTopButton: require('/opt/jenkins/workspace/ospbuild/osp-store-repository/node/packages/shop/node_modules/@osp/design-system/components/ScrollToTopButton/ScrollToTopButton.vue').default,HydrateableClientOnly: require('/opt/jenkins/workspace/ospbuild/osp-store-repository/node/packages/shop/node_modules/@osp/design-system/components/HydrateableClientOnly/HydrateableClientOnly.vue').default,LayoutType: require('/opt/jenkins/workspace/ospbuild/osp-store-repository/node/packages/shop/components/molecules/layout-type/layout-type.vue').default,IntersectingComponent: require('/opt/jenkins/workspace/ospbuild/osp-store-repository/node/packages/shop/node_modules/@osp/design-system/components/IntersectingComponent/IntersectingComponent.vue').default,Layout: require('/opt/jenkins/workspace/ospbuild/osp-store-repository/node/packages/shop/components/molecules/layout/layout.vue').default,ThreadTaskWrapper: require('/opt/jenkins/workspace/ospbuild/osp-store-repository/node/packages/shop/node_modules/@osp/design-system/components/ThreadTaskWrapper/ThreadTaskWrapper.vue').default})
