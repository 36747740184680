import Vue from 'vue';
import { Link } from '@/node_modules/@osp/design-system/types/link';
import {
	FooterItemsWithIcon,
	FooterItemsWithText,
	FooterSection,
	FooterServiceTileEntry,
} from '@/node_modules/@osp/design-system/types/footer';
import { FooterInfoModel } from '@/node_modules/@osp/design-system/components/FooterInfo/FooterInfo.props';
import { FooterNavigationModel } from '@/node_modules/@osp/design-system/components/FooterNavigation/FooterNavigation.props';
import { FooterNewsletterModel } from '@/node_modules/@osp/design-system/components/FooterNewsletter/FooterNewsletter.props';
import { FooterUspModel } from '@/node_modules/@osp/design-system/components/FooterUsp/FooterUsp.props';
import { LcpOptimizationMixin } from '@/node_modules/@osp/design-system/components/mixins/lcp-optimization-mixin';
import { FooterProps } from './footer.props';
import { useMessageboxStore } from '~/@api/store/messageboxApi';
import { useServerSettingsStore } from '~/@api/store/serverSettingsApi';
import { MessageboxType, PageTypes } from '~/@api/store.types';
import { backend } from '~/@api/backend';
import { loadJsonBody, postJson } from '~/app-utils/http';
import { calculateImageUrl } from '~/assets/js/images';
import { NewsletterSubscribeResponse } from '~/generated/hybris-raml-api';

// Constant(s) -------------------------------------------------------------------------------------

const FOOTERNEWSLETTER_EXCLUDED_PAGES = [
	PageTypes.NEWSLETTER,
	PageTypes.NEWSLETTER_GLOBAL_UNSUBSCRIBE,
	PageTypes.NEWSLETTER_UNSUBSCRIBE,
];
const FOOTERUSP_ICONS = {
	expressdelivery: 'events',
	freeshipping: 'delivery',
	hotline: 'phone',
	invoice: 'order-overview',
};
const FOOTERINFO_EXCLUDED_PAYMENTS = ['pay_bonus', 'pay_giftcard'];

// Component ---------------------------------------------------------------------------------------

export default Vue.extend({
	name: 'Footer',
	mixins: [LcpOptimizationMixin],
	props: FooterProps,
	data() {
		return {
			newsletterLoading: false,
		};
	},
	computed: {
		showFooterNewsletter(): boolean {
			return !FOOTERNEWSLETTER_EXCLUDED_PAGES.includes(this.$route.meta.pageType);
		},
		footerNewsletterData(): FooterNewsletterModel {
			return {
				uid: 'FOOTER_NEWSLETTER',
				loading: this.newsletterLoading,
			};
		},
		footerUspData(): FooterUspModel {
			return {
				uid: 'FOOTER_USP',
				items: Object.entries(this.$i18nKeys.footer.servicetile).map(
					([servicetileName, { headline, text }]: FooterServiceTileEntry) => ({
						icon: FOOTERUSP_ICONS[servicetileName],
						heading: this.$t(headline),
						content: this.$t(text),
					}),
				),
			};
		},
		footerNavigationData(): FooterNavigationModel {
			return {
				uid: 'FOOTER_NAVIGATION',
				items: Object.values(this.$i18nKeys.footer.linkTile).map(
					({ headline, link: rawLinks }): FooterSection => {
						const items = Object.values(rawLinks).map(
							({ text, url }): Link => ({ text: this.$t(text), href: this.$t(url) }),
						);

						return { heading: this.$t(headline), items };
					},
				),
			};
		},
		footerInfoData(): FooterInfoModel {
			const paymentItems = useServerSettingsStore(this.$store)
				.state.settings.paymentModes.filter(
					({ icon }) => icon && !FOOTERINFO_EXCLUDED_PAYMENTS.includes(icon),
				)
				.map((paymentMode) => ({
					uid: paymentMode.code,
					name: `icons/payments/${paymentMode.icon}`,
					label: paymentMode.name,
				}));
			const awardsItems = Object.entries(this.$i18nKeys.footer.awardtile.link).map(
				([uid, { assetId, url, alt }]: [string, { assetId: string; url: string; alt: string }]) => {
					return {
						uid,
						href: this.$t(url),
						media: {
							src: calculateImageUrl(
								this.$store,
								{ assetId: this.$t(assetId) },
								{ preset: 'osp_footer_icon', format: 'png', width: 56 },
							),
							sources: [],
							alt: this.$t(alt),
						},
					};
				},
			);
			const servicesItems = this.setItems(
				{
					...this.$i18nKeys.footer.logoTileOffers,
					...this.$i18nKeys.footer.logoTilePartner,
				},
				'icons/services',
			);
			const socialsItems = this.setItems(this.$i18nKeys.footer.socials, 'icons/socials');
			const copyrightItems = Object.entries(this.$i18nKeys.footer.infoTile.link).map(
				([copyrightName, { text, url }]: FooterItemsWithText) => ({
					uid: copyrightName,
					href: this.$t(url),
					text: this.$t(text),
				}),
			);

			return {
				uid: 'FOOTER_INFO',
				paymentsData: {
					heading: this.$t(this.$i18nKeys.footer.paytile.headline),
					items: paymentItems,
				},
				awardsData: {
					heading: this.$t(this.$i18nKeys.footer.awardtile.headline),
					items: awardsItems,
				},
				servicesData: {
					heading: 'test',
					items: servicesItems,
				},
				socialsData: {
					heading: 'test',
					items: socialsItems,
				},
				copyrightData: {
					heading: this.$t(this.$i18nKeys.footer.infoTile.copy.text),
					items: copyrightItems,
				},
				intersectingModeOn: true,
			};
		},
	},
	methods: {
		setItems(rawData: any, namePrefix: string) {
			return Object.entries(rawData).map(([uid, { icon, url, alt }]: FooterItemsWithIcon) => ({
				uid,
				name: `${namePrefix}/${this.$t(icon)}`,
				href: this.$t(url),
				label: this.$t(alt),
			}));
		},
		async handleNewsletterSubmit(data) {
			this.newsletterLoading = true;

			const response = await postJson(
				backend.API.V2.NEWSLETTER.SUBSCRIBE(this.$store),
				{
					email: data.email,
					gender: {
						code: data.gender,
					},
				},
				this.$store,
			);
			if (response?.ok) {
				const newsletterRegistrationResponse = (await loadJsonBody(response, this.$store))
					.json as NewsletterSubscribeResponse;

				this.$refs.footerNewsletter.clearFields();
				this.showNewsletterMessage(newsletterRegistrationResponse);
			}

			this.newsletterLoading = false;
		},
		showNewsletterMessage(newsletterRegistrationResponse: NewsletterSubscribeResponse) {
			useMessageboxStore(this.$store).api.addMessage({
				global: true,
				dismissible: true,
				key: 'backend',
				text: newsletterRegistrationResponse.message.message,
				type: MessageboxType[newsletterRegistrationResponse.message.severity],
			});
		},
	},
});
