import { render, staticRenderFns } from "./footer.vue?vue&type=template&id=a96995bc&"
import script from "./footer.ts?vue&type=script&lang=ts&"
export * from "./footer.ts?vue&type=script&lang=ts&"
import style0 from "./footer.scss?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IntersectingComponent: require('/opt/jenkins/workspace/ospbuild/osp-store-repository/node/packages/shop/node_modules/@osp/design-system/components/IntersectingComponent/IntersectingComponent.vue').default})
